import { Injectable } from '@angular/core';
import { TrackingService } from '@x/common/tracking';
import {
  TCartEventFlow,
  TECartRemoveItem,
  TECartUpdateItem,
} from '@x/common/tracking/event-models';
import { IShopOrderItem } from '@x/ecommerce/shop-client';
import { ShopUpdateCartItemsItemInput } from '@x/schemas/ecommerce';

@Injectable({
  providedIn: 'root',
})
export class CartEventsService {
  constructor(private trackingService: TrackingService) {}

  cartItemUpdatedEvent(
    productVariantId: number,
    updatedOrderItems: IShopOrderItem[],
    existingOrderItem: IShopOrderItem | undefined | null,
    currency: string | null | undefined,
  ) {
    let orderItemAdded = updatedOrderItems.find((i) => i.variantId === productVariantId);

    if (!orderItemAdded) return;

    let flow: TCartEventFlow = 'add';

    if (existingOrderItem) {
      const quantityAdded = orderItemAdded.quantity - existingOrderItem.quantity;
      if (quantityAdded < 0) flow = 'remove';

      orderItemAdded = { ...orderItemAdded, quantity: Math.abs(quantityAdded) };
    }

    this.trackingService.sendEvent(
      new TECartUpdateItem(this.trackingService.routeOrigin, {
        items: [orderItemAdded],
        currency,
        flow,
      }),
    );
  }

  cartItemsUpdatedEvent(
    updatedOrderItems: IShopOrderItem[],
    queryItems: ShopUpdateCartItemsItemInput[],
    existingOrderItems: IShopOrderItem[] | undefined | null,
    currency: string | null | undefined,
  ) {
    const itemsAdded = updatedOrderItems.filter((item) => {
      return queryItems.some((i) => i.productVariantId === item.variantId);
    });

    if (existingOrderItems) {
      itemsAdded.forEach((item) => {
        const existingItem = existingOrderItems.find((i) => i.variantId === item.variantId);
        if (existingItem) {
          item = { ...item, quantity: item.quantity - existingItem.quantity };
        }
      });
    }

    this.trackingService.sendEvent(
      new TECartUpdateItem(this.trackingService.routeOrigin, {
        items: itemsAdded,
        currency: currency,
      }),
    );
  }

  cartItemDeleted(itemToRemoveInCurrentState: IShopOrderItem) {
    this.trackingService.sendEvent(
      new TECartRemoveItem(this.trackingService.routeOrigin, {
        items: [itemToRemoveInCurrentState],
      }),
    );
  }
}
