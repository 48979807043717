import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  TECartCollection,
  TECartDeliverySlot,
  TECartRemoveItem,
  TECartShippingAddress,
} from '@x/common/tracking/event-models/cart.events';
import { TECheckoutAction } from '@x/common/tracking/event-models/checkout.events';
import { TrackingService } from '@x/common/tracking/tracking.service';
import {
  CancelExpressCheckoutPayment,
  CheckoutCancelPayment,
  CheckoutClearPaymentMethodType,
  CheckoutClearState,
  CheckoutClearToken,
  CheckoutCreatePayment,
  CheckoutCreateWalletPayment,
  CheckoutDeleteOrderItem,
  CheckoutDeleteOrderItems,
  CheckoutEnquireOrder,
  CheckoutFetchChannel,
  CheckoutFetchOrder,
  CheckoutFetchShippingSlots,
  CheckoutOrder,
  CheckoutPaymentOptionType,
  CheckoutRefreshOrder,
  CheckoutRequestShippingSlot,
  CheckoutSavePaymentMethod,
  CheckoutSetOrder,
  CheckoutSetPaymentMethodType,
  CheckoutUpdateOrderAddress,
  CheckoutUpdateOrderCollectionPoint,
  CheckoutUpdateOrderCoupon,
  CheckoutUpdateOrderGiftOptions,
  CheckoutUpdateOrderInstructions,
  CheckoutUpdateOrderItem,
  CheckoutUpdatePayment,
  ConfirmExpressCheckoutPayment,
  ReconCheckoutPayment,
} from '@x/ecommerce-shop/app/checkout/state/checkout-order.actions';
import { CartEventsService } from '@x/ecommerce-shop/app/core/order/services/cart-events.service';
import { RoutingContextService } from '@x/ecommerce-shop/app/core/routing/routing-context.service';
import { ShopStorage } from '@x/ecommerce-shop/app/core/shop-storage/shop-storage.service';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';
import {
  IShopAddress,
  IShopChannelCheckoutDetail,
  IShopCheckoutOrder,
  IShopCheckoutOrderPayment,
  IShopCheckoutOrderRequirement,
  IShopCheckoutOrderShippingSlot,
  IShopOrderItem,
  IShopPayment,
  IShopPaymentMethod,
  IShopPaymentMethodInput,
  IShopUserPaymentMethod,
  IShopWalletTransaction,
  ShopChannelService,
  ShopCheckoutOrderService,
  ShopOrderService,
  ShopPaymentService,
  ShopWalletService,
  TShopCheckOutOrderAgreement,
} from '@x/ecommerce/shop-client';
import { OrderState } from '@x/schemas/ecommerce';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ReferrerSet, ReferrerUpdateOrder } from '../../core/referrer/state/referrer.actions';

export type PaymentMethodOptionTypes = 'new' | 'saved' | 'used' | 'wallet' | 'voucher';
export type IPaymentMethodOption = Record<number, PaymentMethodOptionTypes>;

export interface CheckoutOrderStateModel {
  order: IShopCheckoutOrder | null;
  shopPayment: IShopPayment | null;
  shopWalletTransaction: IShopWalletTransaction | null;
  shippingSlots: IShopCheckoutOrderShippingSlot[];
  channel: IShopChannelCheckoutDetail | null;
  paymentMethodType: IShopPaymentMethodInput | null;
  savePaymentMethod: boolean;
  paymentMethodOption: IPaymentMethodOption | null;
}

const defaults: CheckoutOrderStateModel = {
  channel: null,
  paymentMethodType: null,
  order: null,
  shopPayment: null,
  shopWalletTransaction: null,
  shippingSlots: [],
  savePaymentMethod: true,
  paymentMethodOption: null,
};

@State<CheckoutOrderStateModel>({
  name: 'checkout',
  defaults,
})
@Injectable()
export class CheckoutOrderState {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private shopCheckoutOrderService: ShopCheckoutOrderService,
    private shopPaymentService: ShopPaymentService,
    private shopWalletService: ShopWalletService,
    private shopChannelService: ShopChannelService,
    private shopStorage: ShopStorage,
    private routingContextService: RoutingContextService,
    private shopOrderService: ShopOrderService,
    private trackingService: TrackingService,
    private cartEventService: CartEventsService,
    @Inject(SHOP_CONFIG) private shopConfig: IShopConfig,
  ) {}

  @Selector()
  static checkoutRequirements(state: CheckoutOrderStateModel): IShopCheckoutOrderRequirement[] {
    return state.order?.checkoutRequirements?.requirements ?? [];
  }

  @Selector()
  static isEnquiry(state: CheckoutOrderStateModel): boolean {
    return state.channel?.enquiryOnly ?? false;
  }

  @Selector()
  static isVirtualShipment(state: CheckoutOrderStateModel): boolean {
    return state.order?.shipment?.requiredAddressType === 'VIRTUAL';
  }

  @Selector()
  static state(state: CheckoutOrderStateModel): OrderState | undefined {
    return state.order?.state;
  }

  @Selector()
  static canCheckout(state: CheckoutOrderStateModel): boolean {
    const bool = state.order?.checkoutRequirements?.canCheckout;

    if (typeof bool === 'boolean') return bool;
    return false;
  }

  @Selector()
  static order(state: CheckoutOrderStateModel): IShopCheckoutOrder | null {
    return state.order;
  }

  @Selector()
  static orderItems(state: CheckoutOrderStateModel): IShopOrderItem[] {
    return state.order?.items ?? [];
  }

  @Selector()
  static slotRequired(state: CheckoutOrderStateModel): boolean {
    const bool = state.order?.shipment?.slotRequired;

    if (typeof bool === 'boolean') return bool;
    return false;
  }

  @Selector()
  static noItems(state: CheckoutOrderStateModel): boolean {
    return state.order?.items.length === 0;
  }

  @Selector()
  static shopPayment(state: CheckoutOrderStateModel): IShopPayment | null {
    return state.shopPayment;
  }

  @Selector()
  static shopWalletTransaction(state: CheckoutOrderStateModel): IShopWalletTransaction | null {
    return state.shopWalletTransaction;
  }

  @Selector()
  static shippingAddress(state: CheckoutOrderStateModel): IShopAddress | undefined | null {
    return state.order?.shippingAddress;
  }

  @Selector()
  static regionId(state: CheckoutOrderStateModel) {
    return state.order?.shippingAddressRegion?.id;
  }

  @Selector()
  static channelRegionIds(state: CheckoutOrderStateModel): number[] {
    return state.channel?.regions.map((r) => r.id) ?? [];
  }

  @Selector()
  static isCollection(state: CheckoutOrderStateModel): boolean {
    return state.order?.shipment?.collection ?? false;
  }

  @Selector()
  static processedPayments(state: CheckoutOrderStateModel): IShopCheckoutOrderPayment[] {
    return (
      state.order?.payments.filter((p) => !['REDEMPTION', 'PROCESSING'].includes(p.state)) ?? []
    );
  }

  @Selector()
  static walletTransactions(state: CheckoutOrderStateModel): IShopWalletTransaction[] {
    return state.order?.walletTransactions ?? [];
  }

  @Selector()
  static processingPayment(state: CheckoutOrderStateModel): IShopPayment | undefined {
    return state.order?.payments.find((payment) =>
      ['REDEMPTION', 'PROCESSING'].includes(payment.state),
    );
  }

  @Selector()
  static shippingSlots(state: CheckoutOrderStateModel): IShopCheckoutOrderShippingSlot[] {
    return state.shippingSlots;
  }

  @Selector()
  static selectableShippingSlots(state: CheckoutOrderStateModel): IShopCheckoutOrderShippingSlot[] {
    return state.shippingSlots.filter(
      (slot) => slot.isAvailable || slot.unavailableReason === 'PARTIAL_STOCK_QUANTITY',
    );
  }

  @Selector()
  static availableShippingSlots(state: CheckoutOrderStateModel): IShopCheckoutOrderShippingSlot[] {
    return state.shippingSlots.filter((slot) => slot.isAvailable);
  }

  @Selector()
  static selectedSlotId(state: CheckoutOrderStateModel) {
    return state.order?.shipment?.requestedSlotId;
  }

  @Selector()
  static paymentMethodType(state: CheckoutOrderStateModel): IShopPaymentMethodInput | null {
    return state.paymentMethodType;
  }

  @Selector()
  static paymentMethodOption(state: CheckoutOrderStateModel): IPaymentMethodOption | null {
    return state.paymentMethodOption;
  }

  @Selector()
  static requiredAgreements(state: CheckoutOrderStateModel): TShopCheckOutOrderAgreement[] {
    return state.order?.requiredAgreements ?? [];
  }

  @Action(CheckoutFetchOrder)
  fetch(
    { dispatch, patchState }: StateContext<CheckoutOrderStateModel>,
    { id }: CheckoutFetchOrder,
  ) {
    return this.shopCheckoutOrderService
      .fetchOrder({ id, locale: this.locale })
      .pipe(
        switchMap((order) =>
          dispatch([
            new CheckoutSetOrder(order),
            new CheckoutFetchChannel(order.channelCode),
            new ReferrerUpdateOrder(order.id, order.referrerCode),
          ]),
        ),
      );
  }

  @Action(CheckoutRefreshOrder)
  refreshOrder(state: StateContext<CheckoutOrderStateModel>) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return state.dispatch(new CheckoutFetchOrder(orderId));
  }

  @Action(CheckoutSetOrder)
  setOrder({ patchState }: StateContext<CheckoutOrderStateModel>, { order }: CheckoutSetOrder) {
    patchState({ order: { ...order } });
  }

  @Action(CheckoutUpdateOrderItem, { cancelUncompleted: true })
  updateCartItem(
    state: StateContext<CheckoutOrderStateModel>,
    { args, context }: CheckoutUpdateOrderItem,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const { productVariantId } = args;
    const existingOrderItem = state
      .getState()
      .order?.items.find((i) => i.variantId === productVariantId);

    return this.shopCheckoutOrderService
      .updateOrderItem({
        input: { ...args, ...(!existingOrderItem?.context && { context }), orderId },
        locale: this.locale,
      })
      .pipe(
        tap(({ items, currency }) =>
          this.cartEventService.cartItemUpdatedEvent(
            productVariantId,
            items,
            existingOrderItem,
            currency,
          ),
        ),
        switchMap((order) => state.dispatch(new CheckoutSetOrder(order))),
      );
  }

  @Action(CheckoutDeleteOrderItems)
  deleteCartItems(
    state: StateContext<CheckoutOrderStateModel>,
    { variantIds }: CheckoutDeleteOrderItems,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const itemsToRemove = variantIds.map((productVariantId) => {
      return { productVariantId, quantity: 0 };
    });

    return this.shopCheckoutOrderService
      .updateOrderItems({
        input: { items: itemsToRemove, orderId },
        locale: this.locale,
      })
      .pipe(
        tap(() => {
          const removedOrderItems =
            state.getState().order?.items.filter((i) => variantIds.includes(i.variantId)) ?? [];

          this.trackingService.sendEvent(
            new TECartRemoveItem(this.trackingService.routeOrigin, {
              items: removedOrderItems,
            }),
          );
        }),
        switchMap((newOrderState) => state.dispatch(new CheckoutSetOrder(newOrderState))),
      );
  }

  @Action(CheckoutDeleteOrderItem)
  deleteCartItem(state: StateContext<CheckoutOrderStateModel>, { args }: CheckoutDeleteOrderItem) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const { productVariantId } = args;
    const itemToRemoveInCurrentState = state
      .getState()
      .order?.items.find((i) => i.variantId === productVariantId);

    return this.shopCheckoutOrderService
      .deleteOrderItem({ input: { ...args, orderId }, locale: this.locale })
      .pipe(
        tap(() => {
          if (!itemToRemoveInCurrentState) return;

          this.cartEventService.cartItemDeleted(itemToRemoveInCurrentState);
        }),
        switchMap((order) => state.dispatch(new CheckoutSetOrder(order))),
      );
  }

  @Action(CheckoutUpdateOrderGiftOptions)
  updateCartGiftOptions(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutUpdateOrderGiftOptions,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .updateGiftOptions({ input: { ...args, orderId }, locale: this.locale })
      .pipe(
        tap(() => {
          if (args.isGift) {
            this.trackingService.sendEvent(
              new TECheckoutAction('shopping-bag', { action: 'Gift', step: 1 }),
            );
          }
        }),
        switchMap((order) => state.dispatch(new CheckoutSetOrder(order))),
      );
  }

  @Action(CheckoutUpdateOrderInstructions)
  updateCartInstructions(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutUpdateOrderInstructions,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .updateInstructions({ input: { ...args, orderId }, locale: this.locale })
      .pipe(switchMap((order) => state.dispatch(new CheckoutSetOrder(order))));
  }

  @Action(CheckoutUpdateOrderCoupon)
  updateCartCoupon(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutUpdateOrderCoupon,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .updateCoupon({ input: { ...args, orderId }, locale: this.locale })
      .pipe(switchMap((order) => state.dispatch(new CheckoutSetOrder(order))));
  }

  @Action(CheckoutUpdateOrderAddress)
  updateCartAddress(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutUpdateOrderAddress,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .updateAddress({ input: { ...args, orderId }, locale: this.locale })
      .pipe(
        tap(({ shippingAddress }) => {
          if (!shippingAddress) return;

          this.trackingService.sendEvent(
            new TECartShippingAddress(this.trackingService.routeOrigin, {
              address: shippingAddress,
            }),
          );
        }),
        switchMap((order) => state.dispatch(new CheckoutSetOrder(order))),
      );
  }

  @Action(CheckoutUpdateOrderCollectionPoint)
  updateCartCollectionPoint(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutUpdateOrderCollectionPoint,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .updateCollectionPoint({ input: { ...args, orderId }, locale: this.locale })
      .pipe(
        tap(({ shipment }) => {
          if (!shipment) return;

          this.trackingService.sendEvent(
            new TECartCollection(this.trackingService.routeOrigin, {
              shipment,
            }),
          );
        }),
        switchMap((order) => state.dispatch(new CheckoutSetOrder(order))),
      );
  }

  @Action(ConfirmExpressCheckoutPayment)
  confirmExpressPayment(
    state: StateContext<CheckoutOrderStateModel>,
    { args: { paymentMethodId, orderId } }: ConfirmExpressCheckoutPayment,
  ) {
    const expressCheckoutPayment$ = (orderId: number, paymentMethodId: number) => {
      return this.shopPaymentService.createNewCartPayment({
        orderId,
        paymentMethodId,
        save: false,
        isExpressCheckout: true,
      });
    };

    return expressCheckoutPayment$(orderId, paymentMethodId).pipe(
      tap((shopPayment) => state.patchState({ shopPayment })),
      switchMap((shopPayment) =>
        shopPayment ? state.dispatch(new CheckoutFetchOrder(orderId)) : of(),
      ),
    );
  }

  @Action(ReconCheckoutPayment)
  reconCheckoutPayment(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: ReconCheckoutPayment,
  ) {
    return this.shopPaymentService
      .reconCheckoutPayment(args)
      .pipe(tap((shopPayment) => state.patchState({ shopPayment })));
  }

  @Action(CancelExpressCheckoutPayment)
  cancelExpressCheckoutPayment(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CancelExpressCheckoutPayment,
  ) {
    return this.shopPaymentService
      .cancelCartPayment(args)
      .pipe(tap((shopPayment) => state.patchState({ shopPayment })));
  }

  @Action(CheckoutCreatePayment)
  createShopPayment(state: StateContext<CheckoutOrderStateModel>, { args }: CheckoutCreatePayment) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const newPaymentMethod$ = (orderId: number, method: IShopPaymentMethod) => {
      return this.shopPaymentService.createNewCartPayment({
        orderId,
        paymentMethodId: method.id,
        save: state.getState().savePaymentMethod,
      });
    };

    const userPaymentMethod$ = (orderId: number, method: IShopUserPaymentMethod) => {
      return this.shopPaymentService.createCartPayment({ orderId, userPaymentMethodId: method.id });
    };

    const determineApiCall$ = (
      orderId: number,
      args: IShopPaymentMethodInput,
    ): Observable<IShopPayment | null> => {
      const { newPaymentMethod, userPaymentMethod } = args;

      if (newPaymentMethod) {
        return newPaymentMethod$(orderId, newPaymentMethod);
      }

      if (userPaymentMethod) {
        return userPaymentMethod$(orderId, userPaymentMethod);
      }

      return of(null);
    };

    return determineApiCall$(orderId, args).pipe(
      tap((shopPayment) => state.patchState({ shopPayment })),
      switchMap((shopPayment) => {
        return shopPayment ? state.dispatch(new CheckoutFetchOrder(orderId)) : of();
      }),
    );
  }

  @Action(CheckoutCancelPayment)
  cancelShopPayment(state: StateContext<CheckoutOrderStateModel>, { args }: CheckoutCancelPayment) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopPaymentService.cancelCartPayment({ paymentId: args }).pipe(
      tap(() => state.patchState({ shopPayment: null })),
      switchMap(() => state.dispatch(new CheckoutFetchOrder(orderId))),
    );
  }

  @Action(CheckoutUpdatePayment)
  updateShopPayment(
    state: StateContext<CheckoutOrderStateModel>,
    { shopPayment }: CheckoutUpdatePayment,
  ) {
    return state.patchState({ shopPayment });
  }

  @Action(CheckoutSetPaymentMethodType)
  setPaymentMethodNew(
    state: StateContext<CheckoutOrderStateModel>,
    { shopPaymentMethodInput }: CheckoutSetPaymentMethodType,
  ) {
    return state.patchState({
      paymentMethodType: { ...shopPaymentMethodInput },
    });
  }

  @Action(CheckoutPaymentOptionType)
  setPaymentOption(state: StateContext<CheckoutOrderStateModel>, input: CheckoutPaymentOptionType) {
    return state.patchState({
      ...input,
    });
  }

  @Action(CheckoutSavePaymentMethod)
  setSavePaymentMethod(
    state: StateContext<CheckoutOrderStateModel>,
    action: CheckoutSavePaymentMethod,
  ) {
    return state.patchState({
      savePaymentMethod: action.savePaymentMethod,
    });
  }

  @Action(CheckoutClearPaymentMethodType)
  clearPaymentMethodNew(state: StateContext<CheckoutOrderStateModel>) {
    state.patchState({ paymentMethodType: null });
  }

  @Action(CheckoutCreateWalletPayment)
  createShopWalletPayment(state: StateContext<CheckoutOrderStateModel>) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopWalletService.createWalletPayment({ orderId }).pipe(
      tap((shopWalletTransaction) => state.patchState({ shopWalletTransaction })),
      switchMap(() => state.dispatch(new CheckoutFetchOrder(orderId))),
    );
  }

  @Action(CheckoutClearToken)
  clearToken({ setState }: StateContext<CheckoutOrderStateModel>) {
    this.shopStorage.orderToken = null;

    setState({ ...defaults });
  }

  @Action(CheckoutClearState)
  checkoutClearState(
    { setState, dispatch }: StateContext<CheckoutOrderStateModel>,
    { redirect }: CheckoutClearState,
  ) {
    setState({ ...defaults });

    if (redirect) dispatch(new Navigate([this.routingContextService.redirectUrl]));
  }

  @Action(CheckoutFetchShippingSlots)
  fetchShippingSlots(state: StateContext<CheckoutOrderStateModel>) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .fetchShippingSlots({
        orderId,
        showUnavailable: this.shopConfig.features.showUnavailableSlots,
      })
      .pipe(tap((shippingSlots) => state.patchState({ shippingSlots })));
  }

  @Action(CheckoutRequestShippingSlot)
  requestShippingSlot(
    state: StateContext<CheckoutOrderStateModel>,
    { args }: CheckoutRequestShippingSlot,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService.requestShipmentSlot({ input: { ...args, orderId } }).pipe(
      tap(({ requestedSlotId }) => {
        if (!requestedSlotId) return;

        this.trackingService.sendEvent(
          new TECartDeliverySlot(this.trackingService.routeOrigin, { requestedSlotId }),
        );
      }),
      switchMap(() => state.dispatch(new CheckoutFetchShippingSlots())),
      switchMap(() => state.dispatch(new CheckoutRefreshOrder())),
    );
  }

  @Action(CheckoutFetchChannel)
  fetchChannel(
    state: StateContext<CheckoutOrderStateModel>,
    { channelCode }: CheckoutFetchChannel,
  ) {
    if (state.getState().channel?.code === channelCode) return;

    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopChannelService
      .fetchChannelCheckoutDetail(channelCode, this.locale)
      .pipe(tap((channel) => state.patchState({ channel })));
  }

  @Action(CheckoutEnquireOrder)
  enquireOrder(state: StateContext<CheckoutOrderStateModel>) {
    if (state.getState().order?.state !== 'CART') return;

    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .enquire({ input: { orderId }, locale: this.locale })
      .pipe(switchMap((order) => state.dispatch(new CheckoutSetOrder(order))));
  }

  @Action(ReferrerSet)
  setReferrer(state: StateContext<CheckoutOrderStateModel>, { referrerCode }: ReferrerSet) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopOrderService
      .updateReferrer({ input: { orderId, referrerCode } })
      .pipe(switchMap(() => state.dispatch(new CheckoutFetchOrder(orderId))));
  }

  @Action(CheckoutOrder)
  checkoutOrder(state: StateContext<CheckoutOrderStateModel>) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopCheckoutOrderService
      .checkoutCart({ input: { orderId }, locale: this.locale })
      .pipe(switchMap((order) => state.dispatch(new CheckoutSetOrder(order))));
  }

  private getOrderIdFromStorage(dispatch: Function): number | undefined {
    const orderId = this.shopStorage.orderToken?.orderId;
    if (!orderId) {
      dispatch(new CheckoutClearState(true));
      return;
    }
    return orderId;
  }
}
